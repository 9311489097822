import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Bonsai"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I'm a `}<strong parentName="p">{`full stack developer`}</strong>{`. I build things in `}<strong parentName="p">{`php`}</strong>{` and `}<strong parentName="p">{`javascript`}</strong>{` with `}<a parentName="p" {...{
        "href": "http://www.laravel.com"
      }}>{`laravel`}</a>{`, `}<a parentName="p" {...{
        "href": "https://vuejs.org/"
      }}>{`vueJS`}</a>{` and `}<a parentName="p" {...{
        "href": "https://nuxtjs.org/"
      }}>{`nuxtJS`}</a>{`.
I deploy with `}<a parentName="p" {...{
        "href": "https://git-scm.com/"
      }}>{`git`}</a>{`, `}<a parentName="p" {...{
        "href": "http://docker.io"
      }}>{`docker`}</a>{` and `}<a parentName="p" {...{
        "href": "https://kubernetes.io/"
      }}>{`kubernetes`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      